import React, { useEffect, useState } from "react"

import devices from "./devices.json"
import exchanges from "./exchanges.json"
import cities from "./cities.json"

import CountUp from "react-countup"
import { getRandomElement, getRandomInt } from "../../utils/random"

const MAX_ROWS = 6
const INTERVAL = 2500

const hash = row => {
  return Object.values(row).join("")
}

const bidPrice = score => (20 + 0.8 * score) / 10000

const makeRow = () => {
  const score =
    Math.min(100, getRandomInt(30, 100) + getRandomInt(0, 50)) -
    getRandomInt(0, 5)
  return {
    exchange: getRandomElement(exchanges),
    city: getRandomElement(cities),
    deviceModel: getRandomElement(devices),
    score,
    bidPrice: `$${bidPrice(score).toFixed(5)}`,
  }
}

const BiddingSection = () => {
  const [rows, setRows] = useState(
    Array.from({ length: MAX_ROWS + 1 }, () => makeRow())
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newRow = makeRow()
      setRows([newRow, ...rows].slice(0, MAX_ROWS + 1))
    }, INTERVAL)

    return () => clearTimeout(timeout)
  }, [rows])

  return (
    <div className="bidding-section">
      <div
        className="table-grid"
        style={{
          height: (MAX_ROWS + 1) * 2.5 + "rem",
        }}
      >
        <div className="table-header">
          <div>Exchange</div>
          <div>City</div>
          <div>Device Model</div>
          <div>Score</div>
          <div>Bid Price</div>
        </div>
        {rows.map((row, i) => (
          <div
            key={i + hash(row)}
            className={[
              "table-row",
              "animated",
              i === 0 ? "fadeInLeft" : "slideInDown",
            ].join(" ")}
          >
            <div>{row.exchange}</div>
            <div>{row.city}</div>
            <div>{row.deviceModel}</div>
            <div className={i === 0 ? "hl" : ""}>
              {i === 0 ? (
                <>
                  <CountUp end={row.score} delay={0.3} duration={1.7} />
                  <span className="star"></span>
                </>
              ) : (
                row.score
              )}
            </div>
            <div
              className={i === 0 ? "hl" : ""}
              style={{ animationDelay: "1.5s", animationDuration: "1s" }}
            >
              <div
                className={i === 0 ? "animated fadeInLeft" : ""}
                style={{ animationDelay: "1.5s" }}
              >
                {row.bidPrice}
              </div>
              {i === 0 && (
                <span
                  className="star"
                  style={{ animationDelay: "1.5s", animationDuration: "1s" }}
                ></span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BiddingSection
